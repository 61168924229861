<template>
	<div class="block-inner">
		<RichEditor v-model="block.content" :inline="true" :block-id="block.id" :paste-tags="false" placeholder="Write a question" @input="$emit('edit')" />
	</div>
</template>

<script>
	import RichEditor from '../RichEditor';

	export default {
		components: {
			RichEditor
		},
		props: {
			block: {
				type: Object,
				required: true
			}
		},

		created() {
			if(typeof this.block.meta !== 'object') {
				this.block.meta = {};
			}

			if(!this.block.meta.timeLimit) {
				this.block.meta.timeLimit = 120;
			}

			if(!this.block.meta.nrOfTries) {
				this.block.meta.nrOfTries = 1;
			}
		}
	};
</script>